import React from 'react';
import { MultiSelectSearch } from '@kite/react-kite-plus';
import { SwimlaneSelectProps } from '../../Interfaces';

const SwimlaneSelect = ({
  initialData,
  onSelectionsChange,
  onChange,
  value,
}: SwimlaneSelectProps) => {
  if (!initialData.length) return <div />;
  return (
    <MultiSelectSearch
      className="align-self-start"
      closeMenuOnSelect={false}
      disabled={false}
      errorMessage=""
      initialData={initialData}
      label="Swimlanes"
      margin=""
      maxWidth="380px"
      minCharacters={1}
      suggestionKey="lane"
      startsWith={true}
      onChange={onChange}
      onSuggestionSelected={() => {}}
      onSelectionsChange={onSelectionsChange}
      placeholder="Select Swimlanes"
      showExpandButton
      tooltip="Select swimlanes to filter missing trailers."
      value={value}
    />
  );
};

export default SwimlaneSelect;
