import axios from 'axios';

const ODA_SERVER = process.env.REACT_APP_ODA_SERVER_URL || ''; // set to localhost:8080 on local

export const generateReport = async (swimlanes) => {
  const queryString = `?swimlanes=${swimlanes.join(',')}`;
  const {
    data: { assets },
  } = await axios.get(`${ODA_SERVER}/api/v1/missing-trailers${queryString}`);
  return assets;
};

export const createReport = async (report) => {
  return axios
    .post(`${ODA_SERVER}/api/v1/reports`, {
      report: {
        content: report.map(
          ({ tms_id, title, package_id, swimlane, year }) => ({
            tms_id,
            title,
            package_id,
            swimlane,
            year,
          })
        ),
      },
    })
    .catch((createReportError) => console.log({ createReportError }));
};

export const getReports = async ({ page, pageSize, sortBy, ascending }) => {
  const url = `${ODA_SERVER}/api/v1/reports?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_direction=${
    ascending ? 'asc' : 'desc'
  }`;
  const {
    data: { total, reports },
  } = await axios.get(url);
  return { total, reports };
};

export const getReport = (id) => {
  return axios.get(`${ODA_SERVER}/api/v1/reports/${id}`).then(({ data }) => {
    const { content: report, count, created_at } = data;
    return { report, count, created_at };
  });
};
