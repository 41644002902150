import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import Context from '../context';
import { Unauthorized } from '../pages';
import { KiteLoader } from '@kite/react-kite';

const AuthWrapper = ({ children }) => {
  const { user, setUser } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthorizeUser();
  }, []);

  const AuthorizeUser = async () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'localhost') {
      setLoading(false);
      return setUser({
        name: 'local-dev',
        authorized: true,
        roles: ['developer-admin'],
      });
    }

    // request user info
    const { data, status } = await axios
      .get('/api/v1/user/info')
      .catch(({ response: { status } }) => ({ status, data: undefined }));

    if (status === 401) return (window.location.pathname = '/api/v1/login');

    setUser({
      ...(data && data.user_info),
      authorized: status === 200,
    });
    setLoading(false);
  };

  if (user.authorized && user.roles?.includes('developer-admin'))
    return children;

  if (loading)
    return (
      <div className="loader-container">
        <KiteLoader />
      </div>
    );

  return <Unauthorized />;
};

export default AuthWrapper;
