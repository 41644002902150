import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';

import './ExistingReportsTable.scss';

const ExistingReportsTable = ({
  report,
  onRowClick,
  onSortClick,
  sortHeader,
  ascending,
  loading,
  noDataMessage,
}) => {
  const columns = [
    {
      label: 'Report Date',
      sortKey: 'created_at',
      render: ({ created_at }) => {
        const temp = created_at.split(' ')[0].split('-');
        const date = `${temp[1]}/${temp[2]}/${temp[0]}`;
        return date;
      },
      size: 3,
    },
    {
      label: 'Number Titles',
      sortKey: 'count',
    },
  ];

  return (
    <SortableTable
      className="reports-table"
      columns={columns}
      tableData={report}
      onRowClick={onRowClick}
      initialSortHeader="title"
      onSortClick={onSortClick}
      sortHeader={sortHeader}
      reverseSort={!ascending}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default ExistingReportsTable;
