import React, { useContext, useState } from 'react';
import Context from '../../context';

import './ImageReportExportModal.scss';
import { withRouter } from 'react-router-dom';
import { KiteSelect } from '@kite/react-kite';
import { Button } from '../index';
import { downloadReport } from '../../apiCalls/ImageReportService';

const ImageReportExportModal = withRouter((props) => {
  const { report_id } = props;
  const { setModal } = useContext(Context);
  const [selected, setSelected] = useState<string>('');

  const onExport = () => {
    const [division, vod_id] = selected.split('-');
    downloadReport(report_id, division, vod_id);
    setModal({ open: false });
  };

  return (
    <div className="image-report-export-modal">
      <h2>Export Reports</h2>
      {props.divisionVODIDs.length ? (
        <KiteSelect
          disabled={false}
          errorMessage=""
          id="search_type"
          inputProps={{}}
          margin=""
          maxWidth=""
          label="Select One"
          name="SearchType"
          onChange={({ target: { value } }) => setSelected(value)}
          placeholder="Division - VOD ID"
          tooltip=""
          value={selected}
        >
          {props.divisionVODIDs.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </KiteSelect>
      ) : null}
      <div className="image-report-filter-buttons">
        <Button
          onClick={() => setModal({ open: false })}
          text="Cancel"
          className="outline"
        />
        <Button onClick={onExport} text="Export" />
      </div>
    </div>
  );
});

export default ImageReportExportModal;
