import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Context from '../context';
import { UserInterface, ModalInterface } from '../Interfaces';

const AppState = ({ children }) => {
  const [swimlanes, setSwimlanes] = useState<string[]>([]);
  const [user, setUser] = useState<UserInterface>({ authorized: undefined });
  const [modal, setModal] = useState<ModalInterface>({ open: false });

  useEffect(() => {
    if (user.authorized)
      axios
        .get(
          'https://spectrum-prod-elb.digitalsmiths.net/sd/spectrum/itemlists'
        )
        .then((result) => {
          setSwimlanes(result.data.sort((a, b) => (a > b ? 1 : -1)));
        })
        .catch((digitalSmithError) => console.log({ digitalSmithError }));
  }, [user]);

  return (
    <Context.Provider
      value={{ swimlanes, setSwimlanes, user, setUser, modal, setModal }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppState;
