import React from 'react';
import { AnalyticsContextProvider } from './analytics/AnalyticsContextProvider';
import './App.css';
import { AppState, Routes, AuthWrapper, Modal, Footer } from './components';

const App = () => {
  return (
    <AnalyticsContextProvider>
      <div className="App">
        <AppState>
          <AuthWrapper>
            <Routes />
            <Modal />
          </AuthWrapper>
        </AppState>
        <Footer />
      </div>
    </AnalyticsContextProvider>
  );
};

export default App;
