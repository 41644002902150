import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';
import { KiteIcon } from '@kite/react-kite';
import './SwimlaneTable.scss';

const SwimlaneTable = ({ swimlane, noDataMessage, onRowClick }) => {
  const columns = [
    {
      label: 'Title',
      sortKey: 'title',
      size: 1,
      sortEnabled: true,
    },
    {
      label: '#Assets',
      sortKey: 'trailer_id',
      sortEnabled: true,
    },
    {
      label: '',
      sortKey: '',
      sortEnabled: false,
      size: 0.1,
      render: (row) => (
        <div key={row.id} className="table-icon">
          <KiteIcon
            margin="5px"
            name="chevron-right"
            color="#0073d1"
            size="20px"
          />
        </div>
      ),
    },
  ];

  return (
    <SortableTable
      className="swimlane-table"
      columns={columns}
      tableData={swimlane}
      onRowClick={onRowClick}
      loading={swimlane.length > 0 ? false : true}
      hoverableRows={false}
      initialSortHeader="title"
      noDataMessage={noDataMessage}
    />
  );
};

export default SwimlaneTable;
