import React from 'react';
import { TitleDetailsButton } from '../TitleDetailsModal/TitleDetailsModal';
import { SortableTable } from '@kite/react-kite-plus';
import './ImageReportTitlesTable.scss';

const ImageReportsTitlesTable = (props) => {
  const {
    titles,
    onRowClick,
    onSortClick,
    sortHeader,
    sortDirection,
    loading,
    noDataMessage,
  } = props;
  return (
    <SortableTable
      className="image-report-titles-table"
      columns={columns}
      tableData={titles}
      onSortClick={onSortClick}
      onRowClick={onRowClick}
      sortHeader={sortHeader}
      reverseSort={sortDirection === 'DESC'}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default ImageReportsTitlesTable;

const columns = [
  {
    label: 'Title',
    sortKey: 'title',
    size: 1.3,
  },
  {
    label: 'Tivo id',
    sortKey: 'tms_id',
    size: 1.3,
  },
  {
    label: 'Has Image',
    sortKey: 'has_image',
    render: ({ has_image }) => {
      return has_image ? 'True' : 'False';
    },
    size: 0.5,
  },
  {
    label: 'Adult',
    sortKey: 'is_adult',
    render: ({ is_adult }) => {
      return is_adult ? 'True' : 'False';
    },
    size: 0.5,
  },
  {
    label: 'Music',
    sortKey: 'is_music',
    render: ({ is_music }) => {
      return is_music ? 'True' : 'False';
    },
    size: 0.5,
  },
  {
    label: 'info',
    sortEnabled: false,
    sortKey: 'is_music',
    render: (props) => {
      return <TitleDetailsButton {...props} />;
    },
    size: 0.1,
  },
];
