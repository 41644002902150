import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import './Swimlane.scss';
import { Link } from 'react-router-dom';
import { SwimlaneTitleTable } from '../../components';
import { fetchSwimlaneData } from '../../apiCalls/SwimlaneService';

const Swimlane = withRouter((props) => {
  const title = props.match.params.title;

  const [swimlaneResult, setSwimlaneResult] = useState<
    { title: String; trailer_id: number; tms_id: String }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `https://spectrum-prod-elb.digitalsmiths.net/sd/spectrum/taps/asset/search/query/curatedlist?twAppNm=TWCTV&twAppVer=2.7&twLibVer=6.24.0&curList=${title}&offset=0&limit=1000&fl=title,id`
      )
      .then((result) => {
        let res = [];
        let tms_id_req = [];
        tms_id_req = result.data.hits.map((item) => {
          return item.id;
        });
        let tms_id_data = {
          tms_ids: [...tms_id_req],
        };
        fetchSwimlaneData(tms_id_data).then((trailer_res) => {
          res = result.data.hits.map((item) => {
            return {
              title: item.title
                ? item.title.en ||
                  item.title['en-AU'] ||
                  item.title.es ||
                  item.title['en-GB'] ||
                  item.title.sv ||
                  item.title['es-ES'] ||
                  item.title.it ||
                  item.title['fr-FR'] ||
                  item.title.la ||
                  item.title.tr ||
                  item.title['pt-BR'] ||
                  item.title.de ||
                  item.title.bs ||
                  item.title.fi
                : '',
              tms_id: item.id,
              trailer_id: trailer_res.batched_video_assets[item.id]
                ? trailer_res.batched_video_assets[item.id].length
                : 0,
            };
          });
          setSwimlaneResult(res);
        });
      })
      .catch((digitalSmithError) => console.log({ digitalSmithError }));
  }, []);

  return (
    <div className="swimlane-page">
      <Link
        to="/swimlanes"
        className="nav__link flex align-self-start back-button"
        data-tag="reportsSwim-return"
      >
        <h3>{'<'} Back to Swimlanes </h3>
      </Link>
      <h1 className="searchPage-Title" data-test="swimlanePage-Title">
        {title}
      </h1>
      <SwimlaneTitleTable
        swimlane={swimlaneResult}
        noDataMessage="Sorry, no results found."
        onRowClick={({ tms_id }) => props.history.push(`/titles/${tms_id}`)}
      />
    </div>
  );
});

export default Swimlane;
