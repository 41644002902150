import { CounterDisposer } from '@amcharts/amcharts4/core';
import axios from 'axios';

const ODA_SERVER = process.env.REACT_APP_ODA_SERVER_URL || ''; // set to localhost:8080 on local
const API_BASE = `${ODA_SERVER}/api/v1/image-reports`;

export const getImageReports = async (query) => {
  const url = `${API_BASE}${query}`;
  const {
    data: { total, image_reports },
  } = await axios.get(url);
  return { total, image_reports: image_reports.map(findPercentMissing) };
};

export const getDivisionVodID = async (id) => {
  const url = `${API_BASE}/${id}/division-vodID`;
  const {
    data: { division_vodIDs },
  } = await axios.get(url);

  return division_vodIDs.map(({ division, vod_id }) => `${division}-${vod_id}`);
};

export const runReportManually = async (division_vodids) => {
  const url = `${API_BASE}/manual-trigger`;
  const {
    data: { status_code },
  } = await axios.post(url, { division_vodids });
  return status_code;
};

export const getTitlePathsUris = async (report_id, tms_id) => {
  const url = `${API_BASE}/${report_id}/titles/${tms_id}`;
  const { data } = await axios.get(url);
  return data;
};

export const getImageReportNetworks = async (report_id, queryString) => {
  const url = `${API_BASE}/${report_id}/networks${queryString}`;
  const {
    data: { networks },
  } = await axios.get(url);
  return {
    networks: networks.map((n) => ({
      ...n,
      percent_missing: ((n.missing_image_count / n.count) * 100).toFixed(2),
    })),
    // total: networks_count.total,
    // missing_image_count: networks_count.missing_image_count,
    // titles_count: networks_count.titles_count,
  };
};

export const getImageReportNetworksSummary = async (report_id, queryString) => {
  const url = `${API_BASE}/${report_id}/networks/summary${queryString}`;
  const {
    data: { count, missing_image_count },
  } = await axios.get(url);
  return {
    count,
    missing_image_count,
  };
};

export const getImageReportFolder = async (report_id, queryString) => {
  const url = `${API_BASE}/${report_id}/folders${queryString}`;
  const { data } = await axios.get(url);
  return {
    ...data,
    folders: data.folders
      ? data.folders.map((n) => ({
          ...n,
          percent_missing: ((n.missing_image_count / n.count) * 100).toFixed(2),
        }))
      : null,
  };
};

export const getImageReportFolderSummary = async (report_id, queryString) => {
  const url = `${API_BASE}/${report_id}/folders/summary${queryString}`;
  const { data } = await axios.get(url);
  return data;
};

const findPercentMissing = (report) => ({
  ...report,
  percent_missing: ((report.missing_image_count / report.count) * 100).toFixed(
    2
  ),
});

export const getImageReport = async (
  { page = 0, pageSize = 50, sortBy, sortDirection },
  reportId,
  path,
  network
) => {
  let query = `?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}`;
  let url_string = `${API_BASE}/${reportId}/`;

  if (path) {
    query = `${query}&path=${path}`;
    url_string = `${url_string}folders`;
  }
  if (network) {
    url_string = `${url_string}networks/${network}`;
  }
  const url = `${url_string}${query}`;
  const res = await axios.get(url);
  const { data: image_report } = res;
  image_report.titles = image_report.titles.map(findPercentMissing);
  return image_report;
};

export const getImageReportTitlesByNetwork = async (
  reportId,
  network,
  queryString
) => {
  const url = `${API_BASE}/${reportId}/networks/${network}${queryString}`;
  const { data } = await axios.get(url);
  return data;
};

export const getImageReportTitlesByNetworkSummary = async (
  reportId,
  network,
  queryString
) => {
  const url = `${API_BASE}/${reportId}/networks/${network}/summary${queryString}`;
  const { data } = await axios.get(url);
  return data;
};

export const getImageReportTitles = async (reportId, queryString) => {
  const url = `${API_BASE}/${reportId}/titles${queryString}`;
  const res = await axios.get(url);
  console.log('data');
  console.log(res);
  const { data: image_report } = res;
  return { image_report: image_report, count: res.data.count };
};

export const getImageReportTitlesSummary = async (reportId, queryString) => {
  const url = `${API_BASE}/${reportId}/titles/summary${queryString}`;
  const res = await axios.get(url);
  const { data: image_report } = res;
  return image_report;
};

export const downloadReport = async (reportId, division, vod_id) => {
  window.location.href = `${API_BASE}/${reportId}/file?division=${division}&vod_id=${vod_id}`;
};
