import React, { useContext, useState, useEffect } from 'react';
import Context from '../../context';
import { KiteLoader } from '@kite/react-kite';
import { KiteIcon } from '@kite/react-kite';

import './TitleDetailsModal.scss';
import { withRouter } from 'react-router-dom';
import { Button } from '../index';
import { getTitlePathsUris } from '../../apiCalls/ImageReportService';

const TitleDetailsModal = withRouter(({ report_id, tms_id }) => {
  const { setModal } = useContext(Context);
  const [paths, setPaths] = useState<string[]>([]);
  const [uris, setUris] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const getPathsUris = async (report_id, tms_id) => {
    setLoading(true);
    const { paths, uris } = await getTitlePathsUris(report_id, tms_id);
    setPaths(paths);
    setUris(uris);
    setLoading(false);
  };

  useEffect(() => {
    getPathsUris(report_id, tms_id);
  }, [report_id, tms_id]);

  return (
    <div className="title-details-modal">
      {loading ? (
        <KiteLoader />
      ) : (
        <>
          <div className="title-details-modal-content">
            <div className="detail-scroll">
              <h2>Paths</h2>
              {paths.map((path) => (
                <div className="detail-line">{path}</div>
              ))}
              <h2>Uris</h2>
              {uris.map((uri) => (
                <div className="detail-line">
                  <a href={`https://api.spectrum.net${uri}`} target="_blank">
                    api.spectrum.net{uri}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Button
        onClick={() => setModal({ open: false })}
        text="Close"
        className="outline"
      />
    </div>
  );
});

const TitleDetailsButton = withRouter(
  ({
    match: {
      params: { report_id },
    },
    tms_id,
  }) => {
    const { setModal } = useContext(Context);

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setModal({
            open: true,
            renderContent: () => (
              <TitleDetailsModal tms_id={tms_id} report_id={report_id} />
            ),
          });
        }}
      >
        <KiteIcon margin="0" name="info-circle" color="#9ba9bd" />
      </div>
    );
  }
);

export default TitleDetailsModal;

export { TitleDetailsButton };
