import React, { useState, useContext } from 'react';
import { KiteIcon } from '@kite/react-kite';
import Context from '../../context';
import { Button, VideoPlayer } from '../../components';
import { createVideoObject } from '../../utils/helpers';

import './AssetModal.scss';

import { AssetModalProps } from '../../Interfaces';
import { VideoJsPlayerOptions } from 'video.js';

const AssetModal = ({ asset }: AssetModalProps) => {
  const { setModal } = useContext(Context);
  const [video, setVideo] = useState<VideoJsPlayerOptions | undefined>(
    undefined
  );

  const trailers = asset.trailers.filter(
    (trailer) => trailer.status === 'MATCHED'
  );

  const openVideoPlayer = (trailer) => {
    setVideo(createVideoObject(trailer));
  };

  return (
    <div className="asset-modal">
      <h1>Preview Title</h1>
      <div className="flex">
        <div className="asset-media">
          <div className="flex wrap">
            <div className="flex column">
              <img alt="poster" src={asset.imageUrl} className="poster-image" />
              poster
            </div>
            <div className="flex column">
              <img
                src={`${asset.imageUrl}?twccategory=Hero`}
                className="hero-image"
                alt="hero"
              />
              hero
            </div>
          </div>
          <div className="asset-modal-tailers">
            <div className="trailers-header">Trailers</div>
            {trailers ? (
              <div className="trailer-status-group">
                {trailers.map((trailer) => (
                  <div
                    className="trailer-link"
                    onClick={() => openVideoPlayer(trailer)}
                  >
                    <KiteIcon size="25px" name="play-f" color="#0073d1" />
                    <div className="trailer-name">{trailer.title}</div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="asset-text">
          <div className="flex">
            <div className="text-title">TMSID</div>
            {asset.id}
          </div>
          <div className="flex">
            <div className="text-title">Title</div>
            {asset.title}
          </div>
          <div className="flex">
            <div className="text-title">Year</div>
            {asset.releaseYear}
          </div>
          <div className="flex">
            <div className="text-title">Rating</div>
          </div>
          <div className="flex">
            <div className="text-title">Min</div>
            {asset.duration / 60}
          </div>
          <div className="flex">
            <div className="text-title">Genres</div>
            {asset.genres}
          </div>
          <div>
            <div className="text-title">Description</div>
          </div>
          <div>{asset.description}</div>
        </div>
      </div>
      <div className="width-100 flex justify-center">
        <Button
          text="Close"
          onClick={() => setModal({ open: false })}
          className="outline"
        />
      </div>
      {video ? (
        <div className="asset-modal-video-player">
          <KiteIcon
            className="close-video-player"
            name="x"
            size="50px"
            color="#ffffff"
            onClick={() => setVideo(undefined)}
          />
          <VideoPlayer autoplay={true} video={video} />
        </div>
      ) : null}
    </div>
  );
};

export default AssetModal;
