import React, { useContext } from 'react';
import Context from '../../context';
import { SortableTable } from '@kite/react-kite-plus';
import { KiteIcon } from '@kite/react-kite';
import {
  archiveTrailer,
  unarchiveTrailer,
} from '../../apiCalls/TrailerService';
import './TrailerTable.scss';

const TrailerTable = (props) => {
  const {
    trailers,
    onRowClick,
    onSortClick,
    sortHeader,
    ascending,
    loading,
    noDataMessage,
    selectedTrailer,
    status,
  } = props;
  const { setModal } = useContext(Context);
  const columns = [
    {
      label: 'Title/File',
      sortKey: 'title',
      size: 1.4,
      render: ({ title, id }) => {
        return (
          <div className="title-container" key="title">
            {selectedTrailer && id === selectedTrailer.id ? (
              <div className="active-row" />
            ) : null}
            <div className="title">{title}</div>
          </div>
        );
      },
    },
    {
      label: 'TMSID',
      sortKey: 'tms_id',
      sortEnabled: false,
    },
    {
      label: 'Package ID',
      sortKey: 'package_id',
      sortEnabled: false,
    },
    {
      label: 'Play',
      sortKey: 'play',
      sortEnabled: false,
      size: 0.8,
      render: (row) => (
        <div
          key={row.id}
          className="play-trailer"
          onClick={(e) => {
            e.stopPropagation();
            onRowClick(row);
          }}
        >
          <KiteIcon margin="5px" name="play-f" color="#0073d1" /> Play Trailer
        </div>
      ),
    },
    {
      label: 'Last Updated',
      sortKey: 'updated_at',
      render: ({ updated_at }) => {
        const temp = updated_at.split(' ')[0].split('-');
        const date = `${temp[1]}/${temp[2]}/${temp[0]}`;
        return <div key="updated_at">{date}</div>;
      },
      size: 0.5,
    },
    {
      label: '',
      sortKey: '',
      sortEnabled: false,
      render: (video) => {
        if (status === 'ARCHIVED')
          return (
            <UnarchiveTrailerButton
              {...props}
              video={video}
              setModal={setModal}
            />
          );
        return (
          <ArchiveTrailerButton {...props} video={video} setModal={setModal} />
        );
      },
      size: 0.1,
    },
  ];
  return (
    <SortableTable
      className="trailer-table"
      columns={columns}
      tableData={trailers}
      onSortClick={onSortClick}
      sortHeader={sortHeader}
      reverseSort={!ascending}
      loading={loading}
      noDataMessage={noDataMessage}
      expansionRender={(asset) => (
        <div>
          {Object.entries(asset).map(([key, value], i) => (
            <div key={`${key}-${i}`}>
              <b>{key}:</b>
              {typeof value === 'object' && value !== null
                ? JSON.stringify(value)
                : value}
            </div>
          ))}
        </div>
      )}
    />
  );
};

const ArchiveTrailerButton = ({ setModal, video, reloadTrailers }) => (
  <div
    key="delete"
    className="delete-trailer-button"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      setModal({
        open: true,
        header: 'Remove Trailer From List',
        text: `Is this a duplicate or incorrect trailer?`,
        style: {
          width: 400,
          height: 250,
        },
        primaryButton: {
          text: 'Remove Trailer',
          className: 'negative',
          onClick: async () => {
            await archiveTrailer(video.id);
            await reloadTrailers();
            setModal({ open: false });
          },
        },
        secondaryButton: {
          text: 'Cancel',
          className: 'outline',
          onClick: () => setModal({ open: false }),
        },
      });
    }}
  >
    <KiteIcon name="trash-f" />
  </div>
);

const UnarchiveTrailerButton = ({ setModal, video, reloadTrailers }) => (
  <div
    key="delete"
    className="delete-trailer"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      setModal({
        open: true,
        header: 'Unarchive Trailer',
        text: `Are you sure you want to restore this trailer?`,
        style: {
          width: 400,
          height: 250,
        },
        primaryButton: {
          text: 'Unarchive',
          // className: 'negative',
          onClick: async () => {
            await unarchiveTrailer(video.id);
            await reloadTrailers();
            setModal({ open: false });
          },
        },
        secondaryButton: {
          text: 'Cancel',
          className: 'outline',
          onClick: () => setModal({ open: false }),
        },
      });
    }}
  >
    <KiteIcon name="restart" />
  </div>
);

export default TrailerTable;
