import React from 'react';
import './Button.scss';
import { ButtonProps } from '../../Interfaces';

const Button = ({ onClick, text, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`reusable-button ${className}`}>
      {text}
    </button>
  );
};

export default Button;
