/* eslint-disable eqeqeq */
import dayjs from 'dayjs';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import { QueryInterface } from '../Interfaces';

export const renderTableDate = (date) => {
  return dayjs(date).format('MM/DD/YYYY hh:mm:ss A');
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'New':
      return 'plus-circle-f';
    case 'Quality Control':
      return 'edit-circle';
    case 'QualityControl':
      return 'edit-circle';
    case 'Ready for Production':
      return 'arrow-up-circle';
    case 'ReadyForProduction':
      return 'arrow-up-circle';
    case 'In Production':
      return 'checkmark-circle-f';
    case 'Production':
      return 'checkmark-circle-f';
    default:
      return '';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'New':
      return '#003561';
    case 'Quality Control':
      return '#63738a';
    case 'QualityControl':
      return '#63738a';
    case 'ReadyForProduction':
      return '#31a2ff';
    case 'Ready for Production':
      return '#31a2ff';
    case 'In Production':
      return '#008516';
    case 'Production':
      return '#008516';
    default:
      return '';
  }
};

export const iconNames = {
  'Audio Description only': 'audio-description',
  'Amplified Audio': 'amplified-audio',
  'Audio Description + Full Audio': 'audio-description',
  'Closed Captioning': 'cc',
  'Sign Language': 'sign-language',
};

export const getOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const sortPriority = () => {
  return (a, b) => {
    // equal items sort equally
    if (a.priorityNumber === b.priorityNumber) {
      return 0;
    }
    // nulls sort after anything else
    if (a.priorityNumber === null) {
      return 1;
    }
    if (b.priorityNumber === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    return a.priorityNumber < b.priorityNumber ? -1 : 1;
  };
};

export const getUnixTime = (date) => {
  return dayjs(date).unix() * 1000;
};

export const createVideoObject = (asset): VideoJsPlayerOptions => {
  return {
    autoplay: true,
    controls: true,
    sourceOrder: true,
    sources: [
      { name: 'hls', type: 'application/x-mpegURL' },
      { name: 'dash', type: 'application/dash+xml' },
      { name: 'mp4', type: 'video/mp4' },
    ].reduce((acc, { name, type }) => {
      if (asset[name])
        acc.push({
          src: asset[name],
          type: type,
        });
      return acc;
    }, [] as { src: string; type: string }[]),
    tracks: asset.captions
      ? ([
          {
            src: asset.captions,
            kind: 'captions',
            srclang: 'en',
          },
        ] as videojs.TextTrackOptions[])
      : [],
  };
};

const createQueryString = (query) => {
  const queryString = Object.entries(query).reduce((qs, [key, value]) => {
    if (!value) return qs;
    if (Array.isArray(value)) {
      return `${qs}${qs ? '&' : '?'}${key}=[${value.join(',')}]`;
    } else {
      return `${qs}${qs ? '&' : '?'}${key}=${value}`;
    }
  }, ``);
  return `${queryString}`;
};

export const useQuery = (defaultQuery = {}) => {
  const queryString = window.location.search;
  const query: QueryInterface = !queryString
    ? defaultQuery
    : queryString
        .slice(1)
        .split('&')
        .reduce(
          (acc, kv) => {
            const [key, value] = kv.split('=');
            if (/\[.*\]/.test(value)) {
              acc[key] = value.slice(1, value.length - 1).split(',');
            } else {
              acc[key] = value;
            }
            return acc;
          },
          { ...defaultQuery }
        );
  return { query, createQueryString };
};
