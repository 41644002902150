import React, { useEffect, useState, useRef } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import { VideoPlayerProps } from '../../Interfaces';

const VideoPlayer = ({ video, showThumbnail, autoplay }: VideoPlayerProps) => {
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const videoNode = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoNode.current !== null && video) {
      if (player) {
        player.src(video.sources as videojs.Tech.SourceObject[]);
      } else {
        const newPlayer = videojs(videoNode.current, {
          width: 640,
          ...video,
          autoplay,
        });

        if (showThumbnail) setPlayerTimeForThumnail(newPlayer);

        setPlayer(newPlayer);
      }
    }
  }, [video]);

  const setPlayerTimeForThumnail = (player) => {
    player.on('loadedmetadata', () => {
      player.currentTime(15);
    });
    player.on('play', () => {
      if (Math.floor(player.currentTime()) === 15) player.currentTime(0);
    });
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return (
    <div className="video-box">
      <div data-vjs-player>
        <video
          crossOrigin="anonymous"
          ref={videoNode}
          className="video-js"
        ></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
