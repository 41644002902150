import React from 'react';
import './SearchSelect.scss';
import { KiteSelect } from '@kite/react-kite';

const TRAILER_STATUS = [
  { value: 'title', label: 'Title' },
  { value: 'cast', label: 'Cast' },
  { value: 'package_id', label: 'Package ID' },
];
const StatusSelect = ({ setSearchBy, searchBy }) => {
  return (
    <KiteSelect
      className="search-select"
      disabled={false}
      errorMessage=""
      id="search_type"
      inputProps={{}}
      margin=""
      maxWidth=""
      label="Search By"
      name="SearchType"
      onChange={({ target: { value } }) => setSearchBy(value)}
      placeholder=""
      tooltip=""
      value={searchBy}
    >
      {TRAILER_STATUS.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </KiteSelect>
  );
};

export default StatusSelect;
