import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';

import './TitleTable.scss';

const TitleTable = ({
  titles,
  onRowClick,
  onSortClick,
  sortHeader,
  ascending,
  loading,
  noDataMessage,
}) => {
  const columns = [
    {
      label: 'Title',
      sortKey: 'title',
      size: 1,
    },
    {
      label: 'Year',
      sortKey: 'releaseYear',
    },
    {
      label: 'TMS ID',
      sortKey: 'id',
    },
    {
      label: '# Trailers',
      sortKey: '',
      render: ({ trailers }) => trailers.length,
      sortEnabled: false,
    },
  ];

  return (
    <SortableTable
      className="title-table"
      columns={columns}
      tableData={titles}
      onRowClick={onRowClick}
      initialSortHeader="title"
      onSortClick={onSortClick}
      sortHeader={sortHeader}
      reverseSort={!ascending}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default TitleTable;
