/* tslint:disable */
import React, { useRef, useLayoutEffect } from 'react';
import { KiteLoader } from '@kite/react-kite';
import './Chart.scss';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const Chart = ({ imageReports }) => {
  const chart = useRef<am4charts.XYChart | null>(null);

  useLayoutEffect(() => {
    if (imageReports) {
      let x = am4core.create('chartdiv', am4charts.XYChart);

      x.paddingRight = 20;

      let data: { date: Date; name: string; value: number }[] = [];
      imageReports.forEach((report) => {
        data.push({
          date: report.created_at.split(' ')[0].replace(/-/g, '/'),
          name: '% missing',
          value: report.percent_missing,
        });
      });

      x.data = data;

      let dateAxis = x.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis!.tooltip!.disabled! = true;
      valueAxis.renderer.minWidth = 35;

      let series = x.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.tooltipText = '{valueY.value}';
      x.cursor = new am4charts.XYCursor();

      chart!.current! = x;
      return () => {
        x.dispose();
      };
    }
  }, [imageReports]);

  return (
    <div
      id="chartdiv"
      style={{ width: '100%', height: '500px', margin: '0px 0px 30px 0px' }}
      data-test="imagesPage-lineChart"
    >
      {!imageReports ? (
        <KiteLoader
          buttonChild={false}
          className="chart-loader"
          loaderTitle="Loading"
          percent={null}
          showPercent
          size="large"
          status=""
          useLight={false}
        />
      ) : null}
    </div>
  );
};
export default Chart;
