import { KiteLoader } from '@kite/react-kite';
import React from 'react';
import './CardTriplet.scss';

const CardTriplet = ({ cards, loading }) => {
  return (
    <div className="card-triplet-container">
      {loading ? (
        <KiteLoader />
      ) : (
        cards.map((card, i) => {
          return card.hidden ? null : (
            <div
              key={i}
              className="card"
              data-test={'imagesReport-cardNetwork-' + ++i}
            >
              <div className="card-info">{card.data}</div>
              <div className="card-title">{card.title}</div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CardTriplet;
