import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';
import './ImageReportNetworksTable.scss';

const ImageNodesNetworkTable = (props) => {
  const {
    networks,
    onRowClick,
    onSortClick,
    sortHeader,
    sortDirection,
    loading,
    noDataMessage,
  } = props;
  return (
    <SortableTable
      className="image-nodes-network-table"
      columns={columns}
      tableData={networks}
      onSortClick={onSortClick}
      onRowClick={onRowClick}
      sortHeader={sortHeader}
      reverseSort={sortDirection === 'DESC'}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default ImageNodesNetworkTable;

const columns = [
  {
    label: 'Network',
    sortKey: 'network',
    size: 2,
  },
  {
    label: 'Count',
    sortKey: 'count',
    size: 1,
  },
  {
    label: 'Missing',
    sortKey: 'missing_image_count',
    size: 1,
  },
  {
    label: '% missing',
    sortKey: 'percent_missing',
    size: 1,
    sortEnabled: false,
  },
];
