import React, { createContext } from 'react';
import { UserInterface, ModalInterface } from './Interfaces';

const swimlanes: string[] = [];
const setSwimlanes: React.Dispatch<React.SetStateAction<string[]>> = () => {};
const modal: ModalInterface = { open: false };
const setModal: React.Dispatch<React.SetStateAction<ModalInterface>> = () => {};
const user: UserInterface = { authorized: undefined };
const setUser: React.Dispatch<React.SetStateAction<UserInterface>> = () => {};

const initialState = {
  swimlanes,
  setSwimlanes,
  user,
  setUser,
  modal,
  setModal,
};

const Context = createContext(initialState);

export default Context;
