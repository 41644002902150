import React, {
  useState,
  useEffect,
  createContext,
  FunctionComponent,
} from 'react';

// Analytics
import AnalyticsService from './Analytics';
import analyticsConfig from './analytics-config';
import packageJson from '../../package.json';

export const AnalyticsContext = createContext<any>(null);

export const AnalyticsContextProvider: FunctionComponent = ({ children }) => {
  const [analytics, setAnalytics] = useState<AnalyticsService | null>(null);

  useEffect(() => {
    const newAnalytics = new AnalyticsService(
      analyticsConfig,
      packageJson.version
    );
    setAnalytics(newAnalytics);
    newAnalytics.track('loginStart', {
      opType: 'ssoAuth',
    });
  }, []);

  return (
    <AnalyticsContext.Provider value={{ analytics, setAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
