import packageJson from '../../package.json';

export default {
  collector: {
    domain: 'internal_apps',
    customer: 'Charter',
    appName: 'SpectrumAccessAdmin',
    appType: 'Web',
    appVersion: packageJson.version,
    deviceType: 'webBrowser',
    requirementsVersion: '1.1',
    msgTriggeredBy: 'user',
    startSession: 'SpectrumAccessAdmin_startSession_generic',
  },
  helix: {
    settings: {
      logLevel: !/(localhost|127.0.0.1)/.test(window.location.href)
        ? 'info'
        : undefined,
    },
  },
};
