import React from 'react';
import logoLight from '../../assets/images/logo_light.svg';
import './Footer.scss';

const Footer = () => (
  <div className="footer ">
    <div className="flex-container-left">
      <img className="footer-logo" src={logoLight} alt="Spectrum" />
      <p className="spacer-1">© 2021 Charter Communications </p>
    </div>
    <div className="flex-container-right">
      <div className="spacer-2">Version 1.1 </div>
    </div>
  </div>
);
export default Footer;
