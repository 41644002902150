import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  Trailers,
  Report,
  Unauthorized,
  Search,
  Reports,
  Title,
  ImageReports,
  ImageReport,
  Swimlanes,
  Swimlane,
  // ImageReportNetworks,
  // ImageNodeReport,
  // ImageReportTitlesByNetwork
} from '../../pages';

import Nav from '../Nav';

const Routes = () => {
  return (
    <>
      {window.location.pathname !== '/unauthorized' && <Nav />}
      <Switch>
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="/trailers" component={Trailers} />
        {/* <Route
          exact
          path="/image-reports/:report_id/networks"
          component={ImageReportNetworks}
        />
        <Route
          path="/image-reports/:report_id/networks"
          component={ImageReportTitlesByNetwork}
        />
        <Route
          path="/image-reports/:report_id/folders"
          component={ImageNodeReport}
        /> */}
        <Route exact path="/image-reports" component={ImageReports} />
        <Route path="/image-reports/:report_id" component={ImageReport} />
        <Route path="/search" component={Search} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/swimlanes" component={Swimlanes} />
        <Route path="/reports/:id" component={Report} />
        <Route path="/titles/:tms_id" component={Title} />
        <Route path="/swimlanetitles/:title" component={Swimlane} />
        <Route path="/">
          <Redirect to="/reports" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
