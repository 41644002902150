import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';
import './ImageReportsTable.scss';

const ImageReportsTable = (props) => {
  const {
    imageReports,
    onRowClick,
    onSortClick,
    sortHeader,
    loading,
    sortDirection,
    noDataMessage,
  } = props;
  return (
    <SortableTable
      className="image-reports-table"
      columns={columns}
      tableData={imageReports}
      onSortClick={onSortClick}
      onRowClick={onRowClick}
      sortHeader={sortHeader}
      reverseSort={sortDirection === 'DESC'}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default ImageReportsTable;

const columns = [
  {
    label: 'Report Date',
    sortKey: 'created_at',
    render: ({ created_at }) => {
      const temp = created_at.split(' ')[0].split('-');
      const date = `${temp[1]}/${temp[2]}/${temp[0]}`;
      return date;
    },
    size: 2,
  },
  {
    label: 'Title Count',
    sortKey: 'count',
    size: 1,
    sortEnabled: false,
  },
  {
    label: 'Missing Images',
    sortKey: 'missing_image_count',
    size: 1,
    sortEnabled: false,
  },
  {
    label: '% Missing',
    sortKey: 'percent_missing',
    size: 1,
    sortEnabled: false,
  },
];
