import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './ImageReportNetworks.scss';
import {
  ImageReportNetworksTable,
  CardTriplet,
  BarChart,
} from '../../components';
import { useQuery } from '../../utils/helpers';

import {
  getImageReportNetworks,
  getImageReportNetworksSummary,
} from '../../apiCalls/ImageReportService';
import { ImageNodeNetworksInterface } from '../../Interfaces';
import { KiteLoader, KitePagination } from '@kite/react-kite';
import { PaginationInterface, ReportInterface } from '../../Interfaces';

const DEFAULT_PAGINATION = {
  sortBy: 'network',
  sortDirection: 'ASC',
  page: 1,
  ascending: true,
  pageSize: 1000,
};

const PAGINATION = {
  sortBy: 'network',
  sortDirection: 'ASC',
  page: 1,
  ascending: true,
  pageSize: 10,
};

const Images = withRouter((props) => {
  const { query, createQueryString } = useQuery(DEFAULT_PAGINATION);
  const {
    history,
    location: { pathname },
    match: {
      params: { report_id },
    },
  } = props;
  const [networks, setNetworks] = useState<ImageNodeNetworksInterface | null>(
    null
  );
  const [loading, setLoading] = useState<boolean | null>(true);

  const [imageReports, setImageReports] = useState<
    ImageNodeNetworksInterface[]
  >([]);
  const [networksData, setNetworksData] = useState<
    { network: String; count: Number; missing_image_count: Number }[]
  >([]);
  const [rawNetworksData, setRawNetworksData] = useState<
    { network: String; count: Number; missing_image_count: Number }[]
  >([]);

  const updateQuery = (query, newPath?) => {
    // const queryString = createQueryString(query);

    const data = rawNetworksData
      ?.sort((a, b) => {
        if (query.sortDirection === 'ASC') {
          return a[query.sortBy] < b[query.sortBy] ? -1 : 1;
        }
        return a[query.sortBy] > b[query.sortBy] ? -1 : 1;
      })
      .slice(
        (query.page - 1) * PAGINATION.pageSize,
        PAGINATION.pageSize * query.page
      );
    setNetworksData(data);
  };

  const [pagination, setPagination] = useState<PaginationInterface>(PAGINATION);
  const [visibleRows, setVisibleRows] = useState<ImageNodeNetworksInterface[]>(
    []
  );
  const [total_image_reports, set_total_image_reports] = useState<number>(0);
  // const [titles_count, set_titles_count] = useState<number>(0);
  // const [missing_images_count, set_missing_images_count] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    loadNetworks();
  }, [JSON.stringify(query)]);
  useEffect(() => {
    setTotalPages(Math.ceil(total_image_reports / pagination.pageSize));
  }, [total_image_reports]);

  const loadNetworks = async () => {
    setLoading(true);
    const queryString = createQueryString(query);
    const [networks, networksSummary] = await Promise.all([
      getImageReportNetworks(report_id, queryString),
      getImageReportNetworksSummary(report_id, queryString),
    ]);

    set_total_image_reports(networks.networks?.length);
    // set_titles_count(networks.titles_count);
    // set_missing_images_count(networks.missing_image_count);
    setNetworks({ ...networks, ...networksSummary });
    setRawNetworksData(networks.networks);
    setNetworksData(networks.networks.slice(0, PAGINATION.pageSize));
    setLoading(false);
  };

  const handleSortClick = (sortHeader) => {
    // console.log(sortHeader);
    const { sortBy, ascending } = pagination;
    const newSortDirection =
      sortBy !== sortHeader || !ascending ? 'ASC' : 'DESC';
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      sortDirection: newSortDirection,
      sortBy: sortHeader,
    });
    setPagination({
      ...pagination,
      page: 1,
      sortBy: sortHeader,
      ascending: newSortDirection === 'ASC',
    });
  };

  const handleNetworkRowClick = ({ network }) => {
    updateQuery(
      {
        ...query,
        page: 1,
        excludeImages: true,
      },
      `/image-reports/${report_id}/networks/${encodeURIComponent(network)}`
    );
    history.push(
      `/image-reports/${report_id}/networks/${encodeURIComponent(network)}`
    );
  };
  const updateVisibleRows = (newPage) => {
    const start = (newPage - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;
    setVisibleRows(imageReports.slice(start, end));
  };
  const handleNextPage = () => {
    const { page, pageSize, sortBy, ascending } = pagination;
    const newPage = page + 1;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
      sortBy,
      sortDirection: ascending ? 'ASC' : 'DESC',
    });
    setPagination({ ...pagination, page: page + 1 });
    updateVisibleRows(page + 1);
  };

  const handlePreviousPage = () => {
    const { page, pageSize, sortBy, ascending } = pagination;
    const newPage = page - 1;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
      sortBy,
      sortDirection: ascending ? 'ASC' : 'DESC',
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const handlePageSelect = (newPage) => {
    const { pageSize, sortBy, ascending } = pagination;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
      sortBy,
      sortDirection: ascending ? 'ASC' : 'DESC',
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };
  const { page } = pagination;

  return (
    <div className="image-report-networks-page">
      <main className="image-report-networks-main">
        {loading ? (
          <>
            <KiteLoader
              buttonChild={false}
              className="chart-loader"
              loaderTitle="Loading"
              percent={null}
              showPercent
              size="large"
              status=""
              useLight={false}
            />
          </>
        ) : (
          <>
            {' '}
            <BarChart networks={networks?.networks} loading={loading} />
            <CardTriplet
              loading={loading}
              cards={[
                { data: networks?.networks?.length, title: 'Networks' },
                { data: networks?.count, title: 'Titles' },
                {
                  data: `${networks?.missing_image_count} ( ${(networks?.count
                    ? (networks?.missing_image_count / networks?.count) * 100
                    : 0
                  ).toFixed(2)} %)`,
                  title: 'Missing Images',
                },
              ]}
            />
            <div
              className="image-report-networks-table-box"
              data-test="imagesPage-networkTable"
            >
              <ImageReportNetworksTable
                onRowClick={handleNetworkRowClick}
                networks={networksData || []}
                onSortClick={handleSortClick}
                sortDirection={pagination.ascending ? 'ASC' : 'DESC'}
                sortHeader={pagination.sortBy || 'network'}
                loading={loading}
                noDataMessage="Sorry, no results found."
              />
              {!loading && totalPages > 1 && (
                <KitePagination
                  totalPages={totalPages}
                  currentPage={Number(page) || 1}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePreviousPage}
                  onPageSelect={handlePageSelect}
                />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
});

export default Images;
