import React, { useState } from 'react';

import { KiteIcon } from '@kite/react-kite';

import './SearchBar.scss';
import { SearchBarProps } from '../../Interfaces';

const SearchBar = (props: SearchBarProps) => {
  const { onSearch, placeholder } = props;
  const [value, _onChange] = useState('');

  const onChange = (val) => {
    if (props.onChange) props.onChange(val);
    _onChange(val);
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(value);
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    onChange('');
    onSearch('');
  };

  const handleChange = (value) => {
    onChange(value);
    if (!value) {
      onSearch('');
    }
  };

  return (
    <div className="search-bar" data-test="trailersPage-searchInput">
      <div className="search-bar__input-wrapper">
        <input
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={(e) => handleKeydown(e)}
          className="search-bar__input"
          placeholder={placeholder || 'search'}
          value={value}
        />
        <KiteIcon
          onClick={() => onSearch(value)}
          className="search-bar__icon"
          name="search"
          size="25px"
        />
        {value && (
          <KiteIcon
            className="search-bar__clear"
            name="x-circle"
            onClick={handleClear}
            size="25px"
          />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
