import React from 'react';
import { SortableTable } from '@kite/react-kite-plus';

import './ReportTable.scss';

const ReportTable = ({
  report,
  onSortClick,
  sortHeader,
  ascending,
  loading,
  noDataMessage,
}) => {
  const columns = [
    {
      label: 'Title',
      sortKey: 'title',
      size: 1,
    },
    {
      label: 'TMS ID',
      sortKey: 'tms_id',
    },
    {
      label: 'Swimlane(s)',
      sortKey: 'swimlane',
    },
  ];

  return (
    <SortableTable
      className="report-table"
      columns={columns}
      tableData={report}
      hoverableRows={false}
      initialSortHeader="title"
      onSortClick={onSortClick}
      sortHeader={sortHeader}
      reverseSort={!ascending}
      loading={loading}
      noDataMessage={noDataMessage}
    />
  );
};

export default ReportTable;
