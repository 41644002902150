import axios from 'axios';

const ODA_SERVER = process.env.REACT_APP_ODA_SERVER_URL || ''; // set to localhost:8080 on local

export const getTrailers = async (
  { page, pageSize, sortBy, ascending },
  searchStr,
  status
) => {
  const statusFilter = status ? `&status=${status}` : '';
  const pagination = `page=${page}&page_size=${pageSize}&sort_by=${sortBy}`;
  const sort = `&sort_direction=${ascending ? 'asc' : 'desc'}`;
  const search = searchStr ? `&search=${searchStr}` : '';
  const query = `?${pagination}${statusFilter}${sort}${search}`;
  const url = `${ODA_SERVER}/api/v1/video-assets${query}`;
  const {
    data: { total, video_assets },
  } = await axios.get(url);
  return { total, video_assets };
};

export const addTrailerToAsset = async (trailer_id, tms_id) => {
  const url = `${ODA_SERVER}/api/v1/video-assets/${trailer_id}/asset/${tms_id}`;
  return axios.put(url);
};

export const archiveTrailer = async (id) => {
  const url = `${ODA_SERVER}/api/v1/video-assets`;
  return axios.put(url, {
    video_asset: { id, archived: true, is_primary: false },
  });
};

export const unarchiveTrailer = async (id) => {
  const url = `${ODA_SERVER}/api/v1/video-assets`;
  return axios.put(url, { video_asset: { id, archived: false } });
};

export const removeTrailerFromTitle = async (id, currentStatus) => {
  const url = `${ODA_SERVER}/api/v1/video-assets`;
  const status =
    currentStatus === 'MATCHED' ? 'PROCESSED_BY_MDS' : currentStatus;
  return axios.put(url, {
    video_asset: { id, tms_id: null, status, is_primary: false },
  });
};

export const setAsPrimary = async (id, tms_id) => {
  const url = `${ODA_SERVER}/api/v1/video-assets/${id}/primary/${tms_id}`;
  return axios.put(url);
};
