import axios from 'axios';
import { AssetInterface } from '../Interfaces';

const ODA_SERVER = process.env.REACT_APP_ODA_SERVER_URL || '';

export const searchAssets = (
  searchTerm,
  searchBy,
  category = 'All',
  limit = 200,
  offset = 0
) => {
  const search = encodeURI(`${searchBy}=${searchTerm}`);
  const type = category === 'All' ? '' : `&type=${category}`;
  return axios
    .get(
      `${ODA_SERVER}/api/v1/assets?${search}${type}&limit=${limit}&offset=${offset}`
    )
    .then(({ data }) => data.assetSearch as AssetInterface[])
    .catch((axiosError) => []);
};
