import React from 'react';
import { KiteSelect } from '@kite/react-kite';

const TRAILER_STATUS = [
  { value: '', label: 'All' },
  { value: 'CREATED', label: 'Created' },
  { value: 'MOVED_TO_S3', label: 'Moved To S3' },
  { value: 'PROCESSED_BY_MDS', label: 'Processed by MDS' },
  { value: 'MATCHED', label: 'Matched' },
  { value: 'ARCHIVED', label: 'Archived' },
];
const StatusSelect = ({ setStatus, status }) => {
  return (
    <KiteSelect
      className="no-margin"
      disabled={false}
      errorMessage=""
      id="TitleType"
      inputProps={{}}
      label="Status"
      margin=""
      maxWidth=""
      name="TitleTple"
      onChange={({ target: { value } }) => setStatus(value)}
      placeholder=""
      tooltip=""
      value={status}
    >
      {TRAILER_STATUS.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </KiteSelect>
  );
};

export default StatusSelect;
