import React, { useContext, useState } from 'react';
import Context from '../../context';

import './ManualTriggerModal.scss';
import { withRouter } from 'react-router-dom';
import { MultiSelectSearch } from '@kite/react-kite-plus';
import { Button } from '../index';
import { runReportManually } from '../../apiCalls/ImageReportService';

const ManualTriggerModal = withRouter((props) => {
  const { setModal } = useContext(Context);
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<(object | string)[]>([]);

  const runReport = async () => {
    const selectedDivisionVodids = selected.map((selection) => {
      const [division, vod_id] =
        typeof selection === 'string' ? selection.split(' - ') : [];
      return { division, vod_id };
    });
    await runReportManually(selectedDivisionVodids);
    setModal({ open: false });
  };
  const onChange = (e: Event) => {
    setValue((e.target as HTMLTextAreaElement).value);
  };

  const onSelectionsChange = (selected: (object | string)[]) => {
    setSelected(selected);
  };

  return (
    <div className="image-report-filter-modal">
      <h2>Select division - vodid pairs</h2>
      <MultiSelectSearch
        className="align-self-start"
        closeMenuOnSelect={false}
        disabled={false}
        errorMessage=""
        initialData={divisionVodids}
        label="Include divisions"
        margin=""
        defaultSelections={[]}
        maxWidth="380px"
        minCharacters={1}
        suggestionKey="key"
        startsWith={true}
        onChange={onChange as (HTMLInputElement) => void}
        onSuggestionSelected={() => {}}
        onSelectionsChange={onSelectionsChange}
        placeholder="Select Division - VOD ID"
        showExpandButton
        tooltip="Select division vod id to filter report."
        value={value}
      />
      <div className="image-report-filter-buttons">
        <Button
          onClick={() => setModal({ open: false })}
          text="Cancel"
          className="outline"
        />
        <Button onClick={runReport} text="Run Report" />
      </div>
    </div>
  );
});

export default ManualTriggerModal;

const divisionVodids = [
  'AAB - AAB',
  'ALB - ALB',
  'ALCO - ALCO',
  'ALCO - alcoa',
  'ALLN - ALLN',
  'ALLN - allendale_fsm',
  'ASHE - ASHE',
  'ASHE - gsaa_fsm',
  'AUG - AUG',
  'AUS - AUS',
  'BAK - BAK',
  'BAYS - BAYS',
  'BAYS - bay_city_fsm',
  'BHM - BHM',
  'BIRM - BIRM',
  'BIRM - birmingham',
  'BKN - BKN',
  'BNG - BNG',
  'BUF - BUF',
  'CAN - CAN',
  'CIN - CIN',
  'CLB - CLB',
  'CLE - CLE',
  'CLT - CLT',
  'COWY - COWY',
  'COWY - COWY',
  'COWY - billings',
  'COWY - canon_city',
  'COWY - casper',
  'COWY - cheyenne',
  'COWY - grand_junction',
  'CSC - CSC',
  'Conops - Online',
  'DAL - DAL',
  'DEL - DEL',
  'DENT - DENT',
  'DENT - fort_worth',
  'DET - DET',
  'ESMR - ESMR',
  'ESMR - marquette_fsm',
  'East - Online',
  'FTWR - FTWR',
  'FTWR - fort_worth',
  'GRD - GRD',
  'GSAA - GSAA',
  'GSAA - gsaa_fsm',
  'GSO - GSO',
  'GWIN - GWIN',
  'GWIN - gwinnett_fsm',
  'HVN - HVN',
  'HWD - HWD',
  'IND - IND',
  'INE - INE',
  'JACK - JACK',
  'JACK - jackson',
  'KEAR - KEAR',
  'KEAR - rochester',
  'KENN - KENN',
  'KENN - kennewick',
  'KSC - KSC',
  'LEED - LEED',
  'LEED - leeds',
  'LNC - LNC',
  'MADI - MADI',
  'MADI - madison_fsm',
  'MEDF - MEDF',
  'MEDF - medford',
  'MKE - MKE',
  'MPKH - MPKH',
  'MPKH - monterey_park',
  'MPKS - MPKS',
  'MPKS - malibu',
  'MTNA - MTNA',
  'MTNA - billings',
  'MW1 - MW1',
  'MW2 - MW2',
  'MW3 - MW3',
  'NAD - NAD',
  'NAE - NAE',
  'NEWN - NEWN',
  'NEWN - gwinnett_fsm',
  'NEWT - NEWT',
  'NEWT - newtown',
  'NMN - NMN',
  'OCF - OCF',
  'OCI - OCI',
  'POR - POR',
  'QUN - QUN',
  'RAL - RAL',
  'RCH - RCH',
  'REDD - REDD',
  'REDD - malibu',
  'REDD - redding',
  'REDD - redding_fsm',
  'REDD - san_luis',
  'RENO - RENO',
  'RENO - reno',
  'ROCH - ROCH',
  'ROCH - rochester',
  'ROCH - rochester_fsm',
  'RTX - RTX',
  'SAX - SAX',
  'SDG - SDG',
  'SFV - SFV',
  'SLDL - SLDL',
  'SLDL - slidell_fsm',
  'SLOT - SLOT',
  'SLOT - san_luis',
  'SMN - SMN',
  'SPNG - SPNG',
  'SPNG - fort_worth',
  'STCK - STCK',
  'STCK - gwinnett_fsm',
  'STCL - STCL',
  'STCL - rochester',
  'STI - STI',
  'STLO - STLO',
  'STLO - st_louis',
  'STVN - STVN',
  'STVN - madison',
  'STVN - madison_fsm',
  'STVN - stevens_pt_fsm',
  'SYR - SYR',
  'TBDX - TBDX',
  'TBDX - slidell_fsm',
  'TPB - TPB',
  'TPF - TPF',
  'TRIC - TRIC',
  'TRIC - tri-cities',
  'WET - WET',
  'WIL - WIL',
  'WORC - WORC',
  'WORC - worcester',
  'West - Online',
  'PKV - pkv',
];
