import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { KiteIcon, KiteFontIcon } from '@kite/react-kite';
import Context from '../../context';
import { getDivisionVodID } from '../../apiCalls/ImageReportService';
import { useQuery } from '../../utils/helpers';
import {
  ImageReportNetworks,
  ImageReportTitles,
  ImageReportTitlesByNetwork,
  ImageReportFilterModal,
  ImageReportExportModal,
  Button,
  ImageReportFolders,
} from '../../components';
import './ImageReport.scss';

const ImageReport = withRouter((props) => {
  const { setModal, modal } = useContext(Context);
  const {
    location: { pathname },
    history,
    match: {
      params: { report_id },
    },
  } = props;
  const [divisionVODIDs, setDivisionVODIDs] = useState([]);

  useEffect(() => {
    getDivisionVodID(report_id).then(setDivisionVODIDs);
  }, [report_id]);

  const { query, createQueryString } = useQuery();

  const createTabURL = (url, q = {}) => {
    const { excludeAdult, excludeMusic, division_vodid } = query;
    const queryString = createQueryString({
      excludeAdult,
      excludeMusic,
      division_vodid,
      ...q,
    });
    return `${url}${queryString}`;
  };

  return (
    <div className="image-report-page width-100 flex column">
      <div className="image-report-page-banner">
        <div
          onClick={() => history.push('/image-reports')}
          className="back-to-reports"
          data-test="imagesReport-returnLink"
        >
          <KiteIcon name="chevron-left" size="20px" color="#ffffff" /> Back to
          Missing Image Reports
        </div>
        <div className="flex justify-between width-100">
          <div className="report-title" data-test="imagesReport-Title">
            Missing Image Report: {report_id}
          </div>
          <div data-test="imagesReport-exportOptions">
            <Button
              className={`export-button ${
                divisionVODIDs.length ? '' : 'disabled'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!divisionVODIDs.length) return;
                if (modal.open) setModal({ open: false });
                else
                  setModal({
                    open: true,
                    renderContent: () => {
                      return (
                        <ImageReportExportModal
                          report_id={report_id}
                          divisionVODIDs={divisionVODIDs}
                        />
                      );
                    },
                  });
              }}
              text="Export Report"
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (!divisionVODIDs.length) return;
                if (modal.open) setModal({ open: false });
                else
                  setModal({
                    open: true,
                    renderContent: () => {
                      return (
                        <ImageReportFilterModal
                          report_id={report_id}
                          divisionVODIDs={divisionVODIDs}
                        />
                      );
                    },
                  });
              }}
              className={`image-reports-filter ${
                divisionVODIDs.length ? '' : 'disabled'
              }`}
            >
              <KiteIcon name="filter-f" size="20px" color="#ffffff" />
              Report View Options
            </div>
          </div>
        </div>
        <div className="view-tabs" data-test="imagesPage-tabOptions">
          <div
            onClick={() =>
              history.push(createTabURL(`/image-reports/${report_id}/networks`))
            }
            className={`view-tab ${
              pathname.includes('networks') ? 'active' : ''
            }`}
          >
            Networks
          </div>
          <div
            onClick={() =>
              history.push(
                createTabURL(`/image-reports/${report_id}/folders`, {
                  path: '.',
                  title: report_id,
                })
              )
            }
            className={`view-tab ${
              pathname.includes('folders') ? 'active' : ''
            }`}
          >
            Paths
          </div>
          <div
            className={`view-tab ${
              pathname.includes('titles') ? 'active' : ''
            }`}
            onClick={() =>
              history.push(createTabURL(`/image-reports/${report_id}/titles`))
            }
          >
            Assets
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path="/image-reports/:report_id/networks"
          component={ImageReportNetworks}
        />
        <Route
          path="/image-reports/:report_id/networks/:network"
          component={ImageReportTitlesByNetwork}
        />
        <Route
          path="/image-reports/:report_id/folders"
          component={ImageReportFolders}
        />
        <Route
          path="/image-reports/:report_id/titles"
          component={ImageReportTitles}
        />
      </Switch>
    </div>
  );
});

export default ImageReport;
