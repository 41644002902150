import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import './Swimlanes.scss';
import { KiteButton, KiteLoader, KitePagination } from '@kite/react-kite';
import axios from 'axios';
import { SwimlaneTable, SwimlaneSearchBar, Button } from '../../components';

import Context from '../../context';

import { PaginationInterface } from '../../Interfaces';
import { useQuery } from '../../utils/helpers';

const Swimlanes = withRouter((props) => {
  const { swimlanes } = useContext(Context);
  const [searchValue, setSearchValue] = useState('');
  const DEFAULT_PAGINATION = {
    sortBy: 'network',
    sortDirection: 'ASC',
    page: 1,
    ascending: false,
    pageSize: 10,
  };

  const [pagination, setPagination] = useState<PaginationInterface>(
    DEFAULT_PAGINATION
  );

  const [total_image_reports, set_total_image_reports] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [swimData, setSwimData] = useState<
    { title: String; trailer_id: Number }[]
  >([]);
  const {
    history,
    location: { pathname },
    match: {
      params: { report_id },
    },
  } = props;
  const updateQuery = (query, newPath?) => {
    const source =
      searchResult && searchResult.length > 0 ? searchResult : swimDataArr;
    const d = source.slice(
      (query.page - 1) * DEFAULT_PAGINATION.pageSize,
      DEFAULT_PAGINATION.pageSize * query.page
    );
    setSwimData(d);
    if (searchResult && searchResult.length > 0) {
      setSearchFilterResult(d);
    }
  };
  const [searchResult, setSearchResult] = useState<
    { title: String; trailer_id: Number }[]
  >([]);
  const [searchFilterResult, setSearchFilterResult] = useState<
    { title: String; trailer_id: Number }[]
  >([]);

  useEffect(() => {
    getSwimlanes();
  }, [swimlanes]);
  useEffect(() => {
    setTotalPages(Math.ceil(total_image_reports / pagination.pageSize));
  }, [total_image_reports]);
  const { query, createQueryString } = useQuery(DEFAULT_PAGINATION);
  const [swimDataArr, setswimDataArr] = useState<
    { title: String; trailer_id: Number }[]
  >([]);
  const getSwimlanes = async () => {
    const data: { title: String; trailer_id: Number }[] = [];
    axios
      .all(
        swimlanes.map((swim) =>
          axios.get(
            `https://spectrum-prod-elb.digitalsmiths.net/sd/spectrum/itemlists/${swim}`
          )
        )
      )
      .then(
        axios.spread((...responses) => {
          responses.map((response) =>
            data.push({
              title: response.data.name,
              trailer_id: response.data.itemIds.length,
            })
          );
          setswimDataArr(data);
          set_total_image_reports(data.length);
          setSwimData(data.slice(0, DEFAULT_PAGINATION.pageSize));
        })
      );
  };

  const onSearch = async () => {
    const data = swimDataArr.filter((swim) =>
      swim.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchResult(data);

    const d = data.slice(0, DEFAULT_PAGINATION.pageSize);

    setSearchFilterResult(d);
    set_total_image_reports(data.length);
  };
  const updateVisibleRows = (newPage) => {
    const start = (newPage - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;
  };
  const handleNextPage = () => {
    const { page, pageSize } = pagination;
    const newPage = page + 1;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
    });
    setPagination({ ...pagination, page: page + 1 });
    updateVisibleRows(page + 1);
  };

  const handlePreviousPage = () => {
    const { page, pageSize } = pagination;
    const newPage = page - 1;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };

  const handlePageSelect = (newPage) => {
    const { pageSize } = pagination;
    updateQuery({
      ...DEFAULT_PAGINATION,
      ...query,
      page: newPage,
      pageSize,
    });
    setPagination({ ...pagination, page: newPage });
    updateVisibleRows(newPage);
  };
  const { page } = pagination;

  return (
    <div className="search-page">
      <h1 className="searchPage-Title" data-test="swimlanePage-Title">
        Swimlanes
      </h1>
      <div
        className="search-controls-swimLanes"
        data-test="searchPage-searchFields"
      >
        <div
          className="search-bar-wrapper-swimLanes flex align-end"
          data-test="searchPage-searchInput"
        >
          <SwimlaneSearchBar onSearch={onSearch} onChange={setSearchValue} />
        </div>
        <div data-test="searchSwimlanes-button">
          <KiteButton
            className="search-bar__button"
            type="primary"
            onClick={onSearch}
          >
            Search Swimlane
          </KiteButton>
        </div>
      </div>
      {swimData && (
        <>
          <SwimlaneTable
            swimlane={searchResult.length > 0 ? searchFilterResult : swimData}
            onRowClick={({ title }) =>
              props.history.push(`/swimlanetitles/${title}`)
            }
            noDataMessage="Sorry, no results found."
          />
          {totalPages > 1 && (
            <KitePagination
              totalPages={totalPages}
              currentPage={Number(page) || 1}
              onNextPage={handleNextPage}
              onPrevPage={handlePreviousPage}
              onPageSelect={handlePageSelect}
            />
          )}
        </>
      )}
    </div>
  );
});

export default Swimlanes;
