import React, { useContext, useState } from 'react';
import Context from '../../context';

import './ImageReportFilterModal.scss';
import { withRouter } from 'react-router-dom';
import { MultiSelectSearch } from '@kite/react-kite-plus';
import { Button } from '../index';
import { useQuery } from '../../utils/helpers';

const ImageReportFilterModal = withRouter((props) => {
  const {
    history,
    location: { pathname },
  } = props;

  const { query, createQueryString } = useQuery();
  const { setModal } = useContext(Context);
  const [excludeAdult, setExcludeAdult] = useState(!!query.excludeAdult);
  const [excludeMusic, setExcludeMusic] = useState(!!query.excludeMusic);
  const [excludeImages, setExcludeImages] = useState(!!query.excludeImages);
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<(object | string)[]>(
    query.division_vodid || []
  );

  const onChange = (e: Event) => {
    setValue((e.target as HTMLTextAreaElement).value);
  };

  const onSelectionsChange = (selected: (object | string)[]) => {
    setSelected(selected);
  };

  const onFilter = () => {
    const updated = { ...query };
    if (!!excludeAdult !== !!updated.excludeAdult) {
      updated['page'] = 1;
      if (updated.excludeAdult) {
        delete updated.excludeAdult;
      } else {
        updated.excludeAdult = true;
      }
    }

    if (!!excludeMusic !== !!updated.excludeMusic) {
      updated['page'] = 1;
      if (updated.excludeMusic) {
        delete updated.excludeMusic;
      } else {
        updated.excludeMusic = true;
      }
    }

    if (!!excludeImages !== !!updated.excludeImages) {
      updated['page'] = 1;
      if (updated.excludeImages) {
        delete updated.excludeImages;
      } else {
        updated.excludeImages = true;
      }
    }

    if (selected !== updated.division_vodid) {
      updated['page'] = 1;
      if (selected.length > 0) {
        updated.division_vodid = selected;
      } else {
        delete updated.division_vodid;
      }
    }

    const queryString = createQueryString(updated);
    setModal({ open: false });
    history.push(`${pathname}${queryString}`);
  };
  return (
    <div
      className="image-report-filter-modal"
      data-test="imagesReport-filterModal"
    >
      <h2>Report Options</h2>
      {props.divisionVODIDs.length ? (
        <MultiSelectSearch
          className="align-self-start"
          closeMenuOnSelect={false}
          disabled={false}
          errorMessage=""
          initialData={props.divisionVODIDs}
          label="Include divisions"
          margin=""
          defaultSelections={query.division_vodid || []}
          maxWidth="380px"
          minCharacters={1}
          suggestionKey="key"
          startsWith={true}
          onChange={onChange as (HTMLInputElement) => void}
          onSuggestionSelected={() => {}}
          onSelectionsChange={onSelectionsChange}
          placeholder="Select Division - VOD ID"
          showExpandButton
          tooltip="Select division vod id to filter report."
          value={value}
        />
      ) : null}
      <div className="image-report-filter-check">
        <input
          checked={excludeMusic}
          type="checkbox"
          onChange={() => setExcludeMusic(!excludeMusic)}
        />{' '}
        Exclude Music Content
      </div>
      <div className="image-report-filter-check">
        <input
          checked={excludeAdult}
          type="checkbox"
          onChange={() => setExcludeAdult(!excludeAdult)}
        />{' '}
        Exclude Adult Content
      </div>
      <div className="image-report-filter-check">
        <input
          checked={excludeImages}
          type="checkbox"
          onChange={() => setExcludeImages(!excludeImages)}
        />{' '}
        Exclude shows with images
      </div>
      <div className="image-report-filter-buttons">
        <Button
          onClick={() => setModal({ open: false })}
          text="Cancel"
          className="outline"
        />
        <Button onClick={onFilter} text="Filter Report" />
      </div>
    </div>
  );
});

export default ImageReportFilterModal;
